<template>
  <div style="width: 100%;background:#F0F3F8;min-width: 1440px">
    <Helper></Helper>
    <!--顶部导航栏-->
    <Navbar2 class="navHeader" @searchKey="getKey" navBg="F0F3F8"></Navbar2>
    <div class="contentBox">
      <div class="titleBox">远程订单</div>
      <el-table
          :header-cell-style="{background:'#1B60EC',color:'#ffffff'}"
          :center="true"
          :data="list"
          style="width: 90%;margin: auto;border-radius:8px">
        <el-table-column
            prop="title"
            label="远程安装服务名称">
        </el-table-column>
        <el-table-column
            prop="money"
            label="支付金额">
          <template slot-scope="scope">
            {{scope.row.money + '元'}}
          </template>
        </el-table-column>
        <el-table-column
            prop="add_time"
            label="支付时间">
        </el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-button size="small" style="color:#1B60EC" @click="openAddQQ(scope.row)">查看工程师</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="width: 14.4rem;text-align: center;margin-top:0.12rem">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.page"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <Footer style="z-index:12;margin-top:0.2rem"></Footer>
    <el-dialog
        :visible.sync="addQQVb"
        width="30%"
        center>
      <div class="serviceRight">
        <img :src="qqImg" alt="" />
        <p>请扫码添加工程师QQ</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Navbar2 from "./componts/Navbar2";
import { getToken } from "../../utils/auth";
import Helper from "./componts/helper"
import {getOrderList} from '../../Api'
import {
  Footer
} from "../../layout/componts";
export default {
  name: "SoftwareOrderIndex",
  components: {
    Navbar2,
    Footer,
    Helper
  },
  mounted() {
  },
  destroy() {
  },
  data() {
    return {
      addQQVb: false,
      qqImg: '',
      params:{
        token:getToken(),
        page:1,
        per_page: 10
      },
      list: [],
      total: 0
    }
  },
  created() {
    this.initData()
  },
  methods: {
    openAddQQ(val){
      this.qqImg = val.qr_code
      this.addQQVb = true
    },
    handleSizeChange(val){
      this.params.per_page = val
      this.params.page = 1
      this.initData()
    },
    handleCurrentChange(val){
      this.params.page = val
      this.initData()
    },
    getKey(){},
    initData(){
      getOrderList(this.params).then(res=>{
        if(res.status === '200'){
          this.list = res.data.data
          this.total = res.data.total
          console.log(res)
        }
      })
    }
  }
};
</script>
<style scoped>
.uploadBox{
  width:12rem;
  background:white;
  margin:auto;
  border-radius:0.08rem;
  height:9rem
}
.navHeader{
  position: sticky;
  top: 0px;
  z-index: 9;
}
.contentBox{
  width:14.4rem;
  margin:auto;
  min-height:8rem
}
.titleBox{
  width: 100%;
  height: 0.44rem;
  color: rgba(0, 0, 0, 100);
  font-size: 0.3rem;
  font-weight: 700;
  text-align: center;
  margin-top:0.4rem;
  margin-bottom:0.28rem
}
.serviceRight {
  /*margin: 0.22rem 0 0 1.94rem;*/
  width: 1.8rem;
  height: 2.2rem;
  opacity: 1;
  margin: auto
}

.serviceRight img {
  width: 1.8rem;
  height: 1.8rem;
}

.serviceRight p {
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-size: 0.14rem;
  font-weight: 500
}
::v-deep .el-dialog{
  height: 3.2rem !important;
  width: 3.2rem !important;
  border-radius: 16px !important;
}
::v-deep .el-dialog__body{
  height: 3.2rem !important;
  width: 3.2rem !important;
  overflow: hidden !important;
}
</style>